<template>
  <v-data-table
    :items="items"
    :headers="headers"
    :loading="isFetchingItems"
    :server-items-length="totalCount"
    :hide-default-footer="true"
  >
    <template v-slot:[`item.workYearStartDate`]="{ item }">
      <span>{{ formatDate(item.workYearStartDate, "string") }} </span>
      <span v-if="item.workYearEndDate"> ~ </span>
      <span> {{ formatDate(item.workYearEndDate, "string") }}</span>
    </template>
    <template v-slot:[`item.actions`]="{ item }" v-if="page === 'edit'">
      <v-btn icon small @click="$emit('editItem', item.id)">
        <v-icon small> mdi-pencil </v-icon>
      </v-btn>
      <v-btn icon small @click="$emit('deleteItem', item)">
        <v-icon small> mdi-delete </v-icon>
      </v-btn>
    </template>
    <template v-slot:header v-if="items.length > 0">
      <tr class="yellow">
        <td class="pl-4 py-4"><span class="subtitle-2"> Toplam: </span></td>
        <td>
          <span class="subtitle-2 pl-4"> {{ total.mainVacationDays }} </span>
        </td>
        <td>
          <span class="subtitle-2 pl-4">
            {{ total.additionalVacationDays }}
          </span>
        </td>
        <td>
          <span class="subtitle-2 pl-4"> {{ total.usedVacationDays }} </span>
        </td>
        <td>
          <span class="subtitle-2 pl-4">
            {{ total.remainingVacationDays }}
          </span>
        </td>
        <td></td>
        <td></td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import datePickerFormatterMixin from "@/mixins/datePickerFormatter";

export default {
  mixins: [datePickerFormatterMixin],
  props: {
    totalCount: {
      type: Number,
      required: true,
    },
    isFetchingItems: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "İş ili", value: "workYearStartDate", sortable: false },
        { text: "Əsas", value: "mainVacationDays", sortable: false },
        { text: "Əlavə", value: "additionalVacationDays", sortable: false },
        {
          text: "İstifadə edilmiş",
          value: "usedVacationDays",
          sortable: false,
        },
        { text: "Qalıq", value: "remainingVacationDays", sortable: false },
        { text: "Qeyd", value: "description", sortable: false },
        { text: "", value: "actions", sortable: false },
      ],
      options: {},
      total: {
        mainVacationDays: 0,
        additionalVacationDays: 0,
        usedVacationDays: 0,
        remainingVacationDays: 0,
      },
    };
  },
  watch: {
    items: {
      handler() {
        this.calculateTotals();
      },
    },
  },
  methods: {
    calculateTotals() {
      this.total = {
        mainVacationDays: 0,
        additionalVacationDays: 0,
        usedVacationDays: 0,
        remainingVacationDays: 0,
      };

      for (let i = 0; i < this.items.length; i++) {
        this.total.mainVacationDays += this.items[i].mainVacationDays;
        this.total.additionalVacationDays += this.items[
          i
        ].additionalVacationDays;
        this.total.usedVacationDays += this.items[i].usedVacationDays;
        this.total.remainingVacationDays += this.items[i].remainingVacationDays;
      }
    },
  },
};
</script>
