<template>
  <v-container fluid>
    <section class="m-inner-section ma-4">
      <div class="m-inner-page">
        <v-row align="center" class="my-0">
          <v-col md="6">
            <h6 class="text-h6 gray--text text--darken-3">
              Əmək məzuniyyətləri
            </h6>
          </v-col>
          <v-col md="6">
            <div class="d-flex justify-end">
              <v-btn
                color="primary"
                depressed
                @click="addNewVacationBalance()"
                v-if="page === 'edit'"
              >
                <v-icon class="mr-2" medium> mdi-plus </v-icon>
                <span> Əlavə et </span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>

      <List
        :totalCount="totalCount"
        :items="items"
        :isFetchingItems="isFetchingItems"
        @editItem="handleEditItem"
        @deleteItem="handleDeleteItem"
        :page="page"
      />

      <VacationForm
        :item="selectedVacationBalance"
        v-model="dialog"
        @close="closeDialog"
        @save="handleSaveNewVacationBalance"
        @update="handleEditVacationBalance"
        v-if="page === 'edit'"
      />

      <ConfirmationPopup
        id="delete-vacation-modal"
        title="Məzuniyyətin silinməsi"
        v-if="deletedVacation"
        :dialog="confirmationDialog"
        @confirm="handleDeleteConfirmation"
        @cancel="handleDeleteCancellation"
        :confirmText="'Sil'"
      >
        Məzuniyyəti silmək istədiyinizə əminsiniz?
      </ConfirmationPopup>
    </section>
  </v-container>
</template>

<script>
import List from "./components/List/index.vue";
import API from "@/services";
import VacationForm from "./components/Form/Vacation.vue";
import bus from "../../../../../../event-bus";
import ConfirmationPopup from "@/shared/Common/ConfirmationPopup.vue";

export default {
  props: {
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      isFetchingItems: false,
      totalCount: 0,
      dialog: false,
      employeeOrderFormValid: false,
      loading: false,
      selectedVacationBalance: null,
      employeeId: null,
      deletedVacation: null,
      confirmationDialog: false,
    };
  },
  components: {
    List,
    VacationForm,
    ConfirmationPopup
  },
  methods: {
    async checkID() {
      if (this.$route.params.id && this.$route.params.id > 0) {
        this.employeeId = this.$route.params.id;
        this.fetchHREmployeeVacationBalances(this.employeeId);
      }
    },
    fetchHREmployeeVacationBalances(id) {
      this.isFetchingItems = true;
      API.fetchHREmployeeVacationBalances(id)
        .then((response) => {
          this.items = response.items;
          this.totalCount = response.totalCount;
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Əmək məzuniyyətlərini əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    insertHREmployeeVacationBalance(id, data) {
      this.loading = true;
      API.insertHREmployeeVacationBalance(id, data)
        .then(() => {
          this.closeDialog();
          this.fetchHREmployeeVacationBalances(this.employeeId);
          bus.$emit("notificationMessage", {
            color: "success",
            text: "Məzuniyyət əlavə edildi.",
          });
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Məzuniyyət əlavə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateHREmployeeVacationBalance(data) {
      this.loading = true;
      API.updateHREmployeeVacationBalance(data)
        .then(() => {
          this.closeDialog();
          this.fetchHREmployeeVacationBalances(this.employeeId);
          bus.$emit("notificationMessage", {
            color: "success",
            text: "Məzuniyyət redaktə edildi.",
          });
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Məzuniyyət redaktə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeDialog() {
      this.selectedVacationBalance = null;
      this.dialog = false;
    },
    handleSaveNewVacationBalance(form) {
      this.insertHREmployeeVacationBalance(this.employeeId, form);
    },
    handleEditVacationBalance(form) {
      this.updateHREmployeeVacationBalance(form);
    },
    async handleEditItem(id) {
      this.selectedVacationBalance = await API.fetchHREmployeeVacationBalance(
        this.employeeId,
        id
      );
      this.dialog = true;
    },
    addNewVacationBalance() {
      this.selectedVacationBalance = null;
      this.dialog = true;
    },
    handleDeleteItem(item) {
      this.deletedVacation = { ...item };
      this.confirmationDialog = true;
    },
    handleDeleteConfirmation() {
      API.deleteHREmployeeVacationBalance(
        this.employeeId,
        this.deletedVacation.id
      )
        .then(() => {
          this.fetchHREmployeeVacationBalances(this.employeeId);
          bus.$emit("notificationMessage", {
            color: "success",
            text: "Məzuniyyət silindi.",
          });
          this.handleDeleteCancellation();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Məzuniyyəti silmək mümkün olmadı.",
          });
        });
    },
    handleDeleteCancellation() {
      this.confirmationDialog = false;
      this.deletedVacation = null;
    },
  },
  created() {
    this.checkID();
  },
};
</script>
<style></style>
