<template>
  <v-dialog v-model="value" persistent scrollable max-width="1000px">
    <v-card>
      <div class="py-5 px-4 border-bottom">
        <span
          class="text-h6 gray--text text--darken-3 font-weight-regular"
          v-text="item ? 'Məzuniyyətin redaktəsi' : 'Yeni məzuniyyət'"
        >
        </span>
      </div>
      <div class="px-4 pt-4 pb-1">
        <v-form
          id="employeeVacationForm"
          ref="employeeVacationForm"
          @submit.prevent="validateForm"
          v-model="employeeVacationFormValid"
          lazy-validation
        >
          <v-row class="my-0">
            <v-col cols="6" class="mb-3">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="vacationText"
                    label="İş ili*"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    filled
                    dense
                    :rules="rules.vacationDate"
                  ></v-text-field>
                </template>
                <v-date-picker
                  range
                  v-model="vacationDate"
                  :month-format="getMonthFormat"
                  :header-date-format="getHeaderDateFormat"
                  :weekday-format="getWeekdayFormat"
                  :first-day-of-week="1"
                  @input="checkDateInput"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="mb-3">
              <v-text-field
                hide-details="auto"
                filled
                dense
                label="Əsas*"
                v-model="form.mainVacationDays"
                :rules="rules.mainVacationDays"
                type="number"
                min="0"
              />
            </v-col>
            <v-col cols="6" class="mb-3">
              <v-text-field
                hide-details="auto"
                filled
                dense
                label="Əlavə*"
                v-model="form.additionalVacationDays"
                :rules="rules.additionalVacationDays"
                type="number"
                min="0"
              />
            </v-col>
            <v-col cols="6" class="mb-3">
              <v-text-field
                hide-details="auto"
                filled
                dense
                label="İstifadə edilmiş*"
                v-model="form.usedVacationDays"
                :rules="rules.usedVacationDays"
                type="number"
                min="0"
              />
            </v-col>
            <v-col cols="6" class="mb-3">
              <v-text-field
                hide-details="auto"
                filled
                dense
                label="Qalıq*"
                v-model="form.remainingVacationDays"
                min="0"
                disabled
              />
            </v-col>

            <v-col cols="6" class="mb-3">
              <v-textarea
                hide-details="auto"
                filled
                dense
                auto-grow
                label="Qeyd"
                rows="1"
                v-model="form.description"                
              />
            </v-col>
          </v-row>
        </v-form>
      </div>
      <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
        <v-btn color="gray lighten-3" depressed @click="closeDialog">
          Bağla
        </v-btn>
        <v-btn
          depressed
          color="success"
          form="employeeVacationForm"
          type="submit"
          v-text="item ? 'Yadda saxla' : 'Yarat'"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import datePickerFormatterMixin from "@/mixins/datePickerFormatter";

export default {
  mixins: [datePickerFormatterMixin],
  props: {
    value: {
      type: Boolean,
    },
    item: {
      type: Object,
    },
  },
  watch: {
    "form.mainVacationDays": {
      handler() {
        this.calculateRemainingVacationDays();
      },
    },
    "form.additionalVacationDays": {
      handler() {
        this.calculateRemainingVacationDays();
      },
    },
    "form.usedVacationDays": {
      handler() {
        this.calculateRemainingVacationDays();
      },
    },
    item: {
      handler() {
        if (this.item) {
          this.form = this.item;
          this.vacationDate[0] = this.item.workYearStartDate
            ? this.dateToPickerFormat(this.item.workYearStartDate, "string")
            : null;
          this.vacationDate[1] = this.item.workYearEndDate
            ? this.dateToPickerFormat(this.item.workYearEndDate, "string")
            : null;
          this.vacationText = this.formatFormDate(this.vacationDate);
        } else {
          this.resetForm();
        }
      },
      deep: true,
    },
    vacationDate: {
      handler() {
        this.vacationText = this.formatFormDate(this.vacationDate);
      },
    },
  },
  data() {
    return {
      form: {
        workYearStartDate: null,
        workYearEndDate: null,
        mainVacationDays: null,
        additionalVacationDays: null,
        usedVacationDays: null,
        remainingVacationDays: null,
        description: null,
      },
      rules: {
        vacationDate: [(v) => !!v || "İş ili tələb olunur"],
        mainVacationDays: [(v) => v >= 0 || "Əsas 0 və 0-dan çox olmalıdır"],
        additionalVacationDays: [
          (v) => {
            if (v != null) {
              return !!v.toString() || "Əlavə tələb olunur";
            } else return "Əlavə tələb olunur";
          },
        ],
        usedVacationDays: [
          (v) => {
            if (v != null) {
              return !!v.toString() || "İstifadə edilmiş tələb olunur";
            } else return "İstifadə edilmiş tələb olunur";
          },
        ],
        remainingVacationDays: [
          (v) => {
            if (v != null) {
              return !!v.toString() || "Qalıq tələb olunur";
            } else return "Qalıq tələb olunur";
          },
        ],
      },
      employeeVacationFormValid: false,
      vacationDate: [],
      dateMenu: false,
      orderTypes: [],
      vacationText: "",
    };
  },
  methods: {
    checkDateInput() {
      if (this.vacationDate.length == 2) {
        this.dateMenu = false;
      }
    },
    resetForm() {
      this.vacationDate = [];
      this.$refs.employeeVacationForm.reset();
      this.form = {
        workYearStartDate: null,
        workYearEndDate: null,
        mainVacationDays: null,
        additionalVacationDays: null,
        usedVacationDays: null,
        remainingVacationDays: null,
        description: null,
      };
    },
    closeDialog() {
      this.$emit("close");
      this.resetForm();
    },
    formatFormDate(date) {
      if (date[1]) {
        this.form.workYearStartDate = date[0];
        this.form.workYearEndDate = date[1];
        return (
          this.formatDate(date[0], "string") +
          " ~ " +
          this.formatDate(date[1], "string")
        );
      } else if (date[0]) {
        this.form.workYearStartDate = date[0];
        return this.formatDate(date[0], "string");
      } else {
        return "";
      }
    },
    calculateRemainingVacationDays() {
      this.form.remainingVacationDays =
        parseInt(this.form.mainVacationDays || 0) +
        parseInt(this.form.additionalVacationDays || 0) -
        parseInt(this.form.usedVacationDays || 0);
    },
    validateForm() {
      const isValid = this.$refs.employeeVacationForm.validate();

      if (this.employeeVacationFormValid && isValid) {
        this.item
          ? this.$emit("update", this.form)
          : this.$emit("save", this.form);
        this.resetForm();
      }
    },
  },
};
</script>
